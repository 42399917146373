<template>
  <div class="flex flex-col flex-grow bg-light">
    <div v-if="loading" class="container flex flex-col gap-10 pt-10 md:pt-32">
      <DsSkeleton class="w-full md:w-96 h-16 mx-auto"/>
      <DsSkeleton class="w-full md:w-96 h-32 mx-auto mt-8"/>
      <DsSkeleton class="w-full md:w-96 h-12 mx-auto"/>
      <DsSkeleton class="w-36 md:w-40 h-12 mx-auto mt-12"/>
    </div>
    <DsAlert
      v-else-if="!!loadingError"
      type="error"
      label="Fehler"
      class="mx-auto my-auto w-80 md:w-96"
    >
      {{ loadingError }}
    </DsAlert>

    <template v-else>
      <Wizard />

      <main
        class="container flex flex-col text-center"
        :class="[isMobile ? 'py-8' : 'pb-8']"
      >
        <section
          v-if="!isMobile"
          class="mb-7 my-2.5"
        >
          <div class="flex flex-row justify-center">
            <div class="w-full md:w-1/2">
              <h1 class="border-b py-3 md:mt-8">
                <SvgVue
                  v-if="route?.name === Step.ABSCHLUSS"
                  icon="icomoon/flag"
                  class="icon icon-emotion-9 Icon Icon--error inline-block"
                />
                {{ routeTitle }}
              </h1>
            </div>
          </div>
        </section>

        <RouterView />

        <DsAlert
          v-if="hasErrors"
          type="error"
          class="my-1 max-w-2xl mx-auto label-margin"
          label="Bitte überprüfen Sie Ihre Eingaben"
        />
      </main>
      <StepActions class="sticky bottom-0" />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';

import SvgVue from '@/application/components/SvgVue';
import { useDetectMobile } from '@/application/composables/detectMobile';
import StepActions from '@/checkup/components/StepActions.vue';
import Wizard from '@/checkup/components/Wizard.vue';
import { checkupErrors, currentRoute, loadSessionData } from '@/checkup/state';
import { Step, StepOrder } from '@/checkup/utils/steps';
import { flatten } from '@/checkup/utils/flatten';
import { fetchDEMVWerte } from '@/checkup/utils/demvWerte';
import { useRouter } from 'vue-router'
import { DsAlert, DsSkeleton } from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';

const router = useRouter();

const { isMobile } = useDetectMobile();

const loading = ref(false);
const loadingError = ref<string | null>(null);

const route = currentRoute;

const routeTitle = computed(() => route.value?.meta?.stepTitle || route.value?.name);

const hasErrors = computed(() => {
  if (route.value && route.value.name && route.value.name in checkupErrors) {
    const errors = checkupErrors[route.value?.name as Step];
    return (
      errors.value !== null && Object.keys(flatten<string[]>(errors.value.errors)).length > 0
    );
  }
  return false;
});

(async () => {
  try {
    loading.value = true
    await Promise.all([fetchDEMVWerte(), loadSessionData()])
    await router.push({ name: StepOrder[0] });
  } catch (error) {
    captureException(error);
    loadingError.value = 'Es gab einen Fehler. Bitte laden Sie die Seite neu oder probieren Sie es zu einem späteren'
      + ' Zeitpunkt nochmal.'
  } finally {
    loading.value = false;
  }
})();
</script>

<style scoped>
::v-deep(h3) {
  margin-bottom: 0;
}
</style>
