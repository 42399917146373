import { publicRoutes } from "@/application/router/public";
import { contractRoutes } from "@/application/router/contracts";
import { checkupRoutes } from "@/application/router/checkup";
import { documentRoutes } from "@/application/router/documents";
import { hintRoutes } from "@/application/router/hints";
import { actionRoutes } from "@/application/router/actions";
import { accountRoutes } from "@/application/router/account";
import { bedarfRoutes } from "@/application/router/bedarf";
import { notFoundRoute } from "@/application/router/404";

export const routes = [
  ...publicRoutes,
  ...contractRoutes,
  ...checkupRoutes,
  ...bedarfRoutes,
  ...documentRoutes,
  ...hintRoutes,
  ...actionRoutes,
  ...accountRoutes,
  ...notFoundRoute,
]
