import { Capacitor } from '@capacitor/core';
import type { DeviceInfo } from '@capacitor/device';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import type { AppInfo } from "@capacitor/app/dist/esm/definitions";

export const platform = Capacitor.getPlatform();
export const isAndroid = platform === 'android';
export const isIos = platform === 'ios';
export const isNativePlatform = Capacitor.isNativePlatform();
export const isWebPlatform = !isNativePlatform;
export const appEnv = import.meta.env.VITE_APP_ENV;
export const viteMode = import.meta.env.MODE;
export const isLocalEnv = import.meta.env.VITE_APP_ENV === 'local';
export const isStagingEnv = import.meta.env.VITE_APP_ENV === 'staging';
export const isProductionEnv = import.meta.env.VITE_APP_ENV === 'production';
export const isDevelopmentBuild = import.meta.env.DEV;
export const isProductionBuild = import.meta.env.PROD;
export const appUrl = import.meta.env.VITE_APP_URL;
export const commit = import.meta.env.VITE_COMMIT;
export const shortCommit = commit.slice(0, 7);

export async function onNative(callback: () => void|Promise<void>) {
  if (isNativePlatform) await callback();
}

let appInfos: null|AppInfo = null;
let deviceInfos: null|DeviceInfo = null;

export async function getAppInfos(): Promise<AppInfo> {
  if (!appInfos) {
    if (isNativePlatform) {
      appInfos = await App.getInfo();
    } else {
      appInfos = {
        version: import.meta.env.VITE_APP_VERSION,
        build: '0',
        id: '0',
        name: 'Finanzmanager',
      }
    }
  }

  return appInfos;
}

export async function getDeviceInfos(): Promise<DeviceInfo> {
  if (!deviceInfos) {
    deviceInfos = await Device.getInfo();
  }

  return deviceInfos;
}

export async function getLiveUpdateChannel(): Promise<string> {
  if (isStagingEnv) return 'Staging';

  const { version } = await getAppInfos();
  if (version) return `Production-${version}`;

  return 'Production';
}

export async function getDeviceName() {
  const deviceInfos = await getDeviceInfos();

  if (deviceInfos.name) return deviceInfos.name;

  return isNativePlatform ? 'Native Mobile Device' : 'Web Device';
}

export async function getVersionString() {
  const { build, version } = await getAppInfos();
  return `${version} (${build}.${shortCommit})`;
}
