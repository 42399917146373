import * as LiveUpdates from "@capacitor/live-updates";
import { captureException } from "@sentry/vue";
import { isLocalEnv, isNativePlatform, getLiveUpdateChannel } from "@/application/utils/envInfo";
import { ref } from "vue";
import * as Sentry from "@sentry/browser";

const isLiveUpdateEnabled = isNativePlatform && !isLocalEnv;
export const updateProgress = ref(0);

export const configureLiveUpdate = async (): Promise<void> => {
  const channel = await getLiveUpdateChannel();
  await LiveUpdates.setConfig({ channel });
  Sentry.setContext('Appflow', { liveUpdateChannel: channel });
}

export const liveUpdateSyncAndReload = async () => {
  if (!isLiveUpdateEnabled) return;

  try {
    const result = await LiveUpdates.sync((progrss) => updateProgress.value = Math.floor(progrss*100));
    if (result.activeApplicationPathChanged) {
      await LiveUpdates.reload();
    }
    updateProgress.value = 0;
  } catch (error: unknown) {
    captureException(error);
  }
};
