<template>
  <ShallowCard
    :highlight="isUnread"
    class="relative"
    @click="removeUnreadHint"
  >
    <span
      v-if="isUnread"
      :class="$style.newBadge"
      class="ml-1.5 badge badge-pill badge-secondary mr-1.5"
    >Neu</span>
    <div class="flex flex-col justify-between">
      <div class="flex flex-row justify-between">
        <div class="text-left truncate">
          <span class="text-gray-800 font-semibold">
            {{ document.type?.name || 'Sonstiges' }}
          </span>
          <div
            class="truncate text-gray-500 text-sm"
            :title="fileName"
          >
            <DsIcon
              :name="document.extension === 'pdf' ? 'file-pdf' : 'file'"
              variant="light"
              class="mr-1.5"
            />
            {{ fileName }}
          </div>
        </div>
        <DsDropdown
          :items="dropdownItems"
          :width="260"
          placement="bottom-end"
          list-class="py-2"
          item-class="pt-0 pb-0"
          @select="handleSelect"
        >
          <template #button>
            <DsButton
              icon="ellipsis"
              icon-align="center"
              variant="clear"
              fixed-width
            />
          </template>

          <template #item="{ item }">
            <div
              class="flex flex-row items-center gap-4 py-2 px-5"
              :class="[{'opacity-50 cursor-not-allowed': item.disabled, [item.class]: !item.disabled}]"
            >
              <DsIcon
                v-if="item.icon"
                :name="item.icon"
                variant="light"
              />
              {{ item.text }}
            </div>
          </template>
        </DsDropdown>
      </div>
      <div class="mt-5">
        <div
          v-if="sparte !== null"
          class="my-1.5 text-sm"
        >
          {{ sparte }}
        </div>
        <div class="flex flex-row items-center gap-3">
          <CardGesellschaft
            v-if="document.company"
            :id="document.company.id"
            :name="document.company.name"
          />
          <div
            class="flex flex-row max-w-[50%] flex-1 items-center"
            @click.stop
          >
            <DsButton
              v-if="!contractNumber && isEditableByUser"
              variant="outline"
              data-testid="associateContractButton"
              @click="showConnectContract = true"
            >
              Vertrag zuordnen
            </DsButton>
            <div
              v-else-if="routingContractId !== null"
              class="flex flex-row gap-1 max-w-full"
            >
              <RouterLink
                v-slot="{ navigate }"
                :to="{
                  name: Finanzmanager.VERTRAG,
                  params: { id: routingContractId }
                }"
                custom
              >
                <DsButton
                  size="sm"
                  variant="clear"
                  icon="external-link-alt"
                  icon-align="right"
                  :handler="navigate"
                  class="max-w-full"
                >
                  {{ contractNumber }}
                </DsButton>
              </RouterLink>
            </div>
          </div>
          <ConnectContractModal
            :show="showConnectContract"
            :document="document"
            :file-name="fileName"
            @close="showConnectContract = false"
            @open="showConnectContract = true"
          />
        </div>
      </div>
    </div>
    <DsModal
      v-if="document.userDocumentId"
      ref="modal"
      title="Datei löschen"
      :variant="ModalVariant.Error"
      icon="trash"
      cancel-label="Abbrechen"
      confirm-label="OK"
    >
      <ModalWrapper>
        Das Löschen der Datei kann nicht rückgängig gemacht werden. Sind Sie sicher, dass sie fortfahren möchten?
      </ModalWrapper>
    </DsModal>
    <CardMeta>
      <div class="py-1.5 pl-2.5 flex flex-grow justify-end text-xs md:text-sm">
        hochgeladen am: {{ formatDate(document.createdAt) }}
      </div>
    </CardMeta>
  </ShallowCard>
</template>

<script setup lang="ts">
import { DsButton, DsDropdown, DsIcon, DsModal, ModalVariant } from '@demvsystems/design-components';
import { captureException } from '@sentry/vue';
import { computed, type Ref, ref } from 'vue';

import type { Document } from '@/api/documents/types';
import { UnreadHintType } from '@/api/unreadHint/types';
import { useUnreadHint } from '@/application/composables/unreadHint';
import CardGesellschaft from '@/contracts/components/CardGesellschaft.vue';
import CardMeta from '@/contracts/components/CardMeta.vue';
import ShallowCard from '@/contracts/components/ShallowCard.vue';
import ConnectContractModal from '@/documents/components/ConnectContractModal.vue';
import { useDocumentStore } from '@/documents/store/documentStore';
import { formatDate } from '@/application/utils/date';
import { spartenMapper } from '@/application/utils/sparteFormatter';
import { useUnreadHintStore } from '@/application/store/unreadHintStore';
import { call } from "@/api/lib/integration";
import { deleteDocument } from "@/api/documents/documentsApi";
import { useFileDownload } from "@/application/composables/fileDownload";
import { Finanzmanager } from "@/application/router/types/types";
import ModalWrapper from "@/application/components/ModalWrapper.vue";

interface DropdownItem {
  icon: string,
  text: string,
  class?: string,
  function: () => void,
  disabled?: boolean,
}

interface Props {
  document: Document,
}

const props = defineProps<Props>();

const { isUnread } = useUnreadHint(UnreadHintType.Document, props.document.id);
const { removeUserDocument } = useDocumentStore();
const { downloadS3File } = useFileDownload();

const modal: Ref<typeof DsModal | null> = ref(null);
const showConnectContract = ref(false);

const sparte = computed(() => props.document.product ? spartenMapper(props.document.product) : null);
const fileName = computed(() => `${props.document.name}.${props.document.extension}`);

function handleDelete(userDocumentId: number | null) {
  modal.value?.open({
    confirmed: () => {
      deleteUserDocument(userDocumentId);
    },
  });
}

const deleteUserDocument = async (userDocumentId: number | null) => {
  if (userDocumentId === null) return;

  await call(
    deleteDocument({ userDocumentId }),
    () => {
      removeUserDocument(userDocumentId)
    },
    (error) => {
      captureException(error)
    },
  );
};
const contractNumber = computed(() => {
  const { contract } = props.document;
  if (!contract) return null;
  if (!contract.name) return 'Vertrag ohne Vertragsnr.';
  return contract.name;
});
const routingContractId = computed(() => {
  const { contract } = props.document;
  if (!contract) return null;
  return contract.id;
});
const isEditableByUser = computed(() => props.document.userDocumentId !== null);
const removeUnreadHint = async () => {
  await useUnreadHintStore().removeUnreadHints([{
    type: UnreadHintType.Document,
    object_id: Number(props.document.id)
  }], true)
}
const dropdownItems = computed((): DropdownItem[] => {
  const items: DropdownItem[] = [
    {
      icon: 'download',
      text: 'Herunterladen',
      function: () => downloadS3File(props.document.urls.download, fileName.value),
    },
    {
      icon: 'trash',
      text: 'Datei löschen',
      class: 'text-red-700',
      disabled: !props.document.canBeDeleted,
      function: () => {
        handleDelete(props.document.userDocumentId)
      }
    },
  ]

  if (props.document.contract) {
    items.splice(1, 0, {
      icon: 'pencil',
      text: 'Vertragszuordnung ändern',
      disabled: !isEditableByUser.value,
      function: () => {
        showConnectContract.value = true
      },
    })
  }

  if (isUnread.value) {
    items.splice(2, 0, {
      icon: 'envelope-open',
      text: 'Als gesehen markieren',
      function: () => removeUnreadHint(),
    })
  }
  return items;
})

function handleSelect(arrayIndex: number) {
  const item = dropdownItems.value[arrayIndex];
  if (item && !item.disabled) {
    item.function();
  }
}
</script>

<style module>
.newBadge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(30%, -50%);
}
</style>
